
import { useStore } from "vuex"
import { computed, defineComponent, ref } from "vue"
import router from "@/router"
import { PurchasingList, PurchasingListRow, Receipt, ReceiptPicture, UploadListType } from "@/modules/admin/purchasing-products/models/listInterfaces"
import { ListStatus, ListActionType, IngredientGroupType, ConfirmationMessages } from "@/modules/admin/purchasing-products/models/enums"
import EditListRow from "@/modules/admin/purchasing-products/components/EditListRow.vue"
import Receipts from "@/modules/admin/purchasing-products/components/Receipts.vue"
import ToggleCheckbox from "@/common/components/ToggleCheckbox.vue"
import moment from "moment"
import { FULLDATE_TIME_FORMAT } from "@/utils/data/moment-formats"
import { TranslationListStatus } from "@/modules/admin/purchasing-products/models/records"
import { uploadListToXLS } from "@/modules/admin/purchasing-products/models/uploadList"

export default defineComponent({
    name: "ListForBuyer",
    components: {
        Receipts,
        EditListRow,
        ToggleCheckbox,
    },
    setup() {
        const store = useStore()
        const isNightSelect = computed(() => store.getters.getIsSelectedNight)
        const backToListTable = () => router.push("/PurchasingListsView")

        const editingList = computed<PurchasingList>(() => {
            renderKey.value
            return JSON.parse(localStorage.getItem("editingList") as string)
        })

        const isEditingAllowed = computed<boolean>(() =>
            editingList.value.status === ListStatus.ACTIVE
            || editingList.value.status === ListStatus.NEW)

        //Data downloading
        const downloadListData = async () => {
            await store.dispatch("downloadManufacturers")
            await store.dispatch("downloadUnits")
            await store.dispatch("downloadPurchasingPlaces")
            await store.dispatch("getIngredients")
            await store.dispatch("downloadPurchasingListRows", editingList.value.id)
            await store.dispatch("downloadPicturesByListId", JSON.parse(localStorage.getItem("editingList")!).id)
            await store.dispatch("downloadReceipts", editingList.value.id)
            store.commit("setListPrice", editingList.value.price)
        }
        downloadListData()
        //INGREDIENTS HANDLING
        //Switchers
        const ingredientGroupSwitched = (isSwitched: boolean) => {
            isSwitched
                ? store.commit("changeIngredientGroupType", IngredientGroupType.CATEGORY)
                : store.commit("changeIngredientGroupType", IngredientGroupType.PLACE)
        }

        const purchasedRowsVisible = ref(localStorage.getItem("purchasedRowsVisible")
            ? JSON.parse(localStorage.getItem("purchasedRowsVisible") as string)
            : false)

        const purchasedRowsVisibleSwitched = (isSwithed: boolean) => {
            purchasedRowsVisible.value = isSwithed
            localStorage.setItem("purchasedRowsVisible", JSON.stringify(isSwithed))
        }
        //Purchasing date is set when check box is switched
        const processPurchasing = (listRow: PurchasingListRow) => {
            setTimeout(() => store.dispatch("processPurchasing", listRow), 300)

        }

        //Ingredients table data forming
        const listRows = computed<Map<string, Map<number, PurchasingListRow>>>(() =>
            store.getters.getPurchasingListRowsTableData(purchasedRowsVisible.value))

        const getTableData = (groupKey: string): Array<PurchasingListRow> => {
            if (listRows.value.has(groupKey))
                return Array.from((listRows.value.get(groupKey) as Map<number, PurchasingListRow>).values())
            else return [] as PurchasingListRow[]
        }


        //Ingredients editing dialog handling
        const editingDialogVisible = ref(false)
        const showEditingDialog = () => editingDialogVisible.value = true
        const hideEditingDialog = () => editingDialogVisible.value = false


        //Ingredients editing, deleting
        const editingRow = ref<PurchasingListRow>({ isPurchased: false })

        const ingredientRowClicked = (row: PurchasingListRow, column: { property: string }) => {
            if (isEditingAllowed.value && column.property !== "isPurchased" && column.property !== "note") {
                editingRow.value = row
                showEditingDialog()
            }
        }

        const deleteRow = (row: PurchasingListRow) => {
            store.commit("removeListRow", row)
        }

        //LIST HANDLING
        //Save, complete, reset, upload buttons
        const saveButtonDisabled = computed(() => store.getters.getPurchasingListRowsTableData.size === 0)
        const sendButtonDisabled = computed(() => !store.getters.isListSaved)
        const completeButtonDisabled = computed<boolean>(() => {
            let counter = 0
            store.getters.getPurchasingListRowsTableData(false).forEach((mapKey: Map<number, PurchasingListRow>) => {
                if (Array.from(mapKey.values()).find((row: PurchasingListRow) => !row.note)) counter++
            })
            if (!store.getters.getReceiptsTableData.size) counter++
            else {
                store.getters.getReceiptsTableData.forEach((receipt: Receipt) => {
                    if (receipt.pictureIds?.length === 0 && !receipt.note) counter++
                })
            }
            return !!counter
        })

        const saveList = async () => {
            const savingRows: PurchasingListRow[] = []
            store.getters.getPurchasingListRowsTableData(true).forEach((category: Map<number, PurchasingListRow>) =>
                savingRows.push(...Array.from(category.values())))

            const savingReceipts:
                Array<{
                    receipt: Receipt
                    pictures: ReceiptPicture[]
                }> =
                (Array.from(store.getters.getReceiptsTableData?.values()) as Array<Receipt>).map((receipt: Receipt) => {
                    if (!receipt.pictureIds) receipt.pictureIds = []
                    receipt.purchasingListId = editingList.value.id
                    receipt.executorId = store.getters.getUserId
                    return {
                        receipt: receipt,
                        pictures: store.getters.getPicturesFromStore(receipt.mapId) ?? []
                    }
                })

            if (!editingList.value.executorIds?.includes(store.getters.getUserId))
                editingList.value.executorIds?.push(store.getters.getUserId)

            editingList.value.price = store.getters.getListPrice
            if (editingList.value.status !== ListStatus.DONE) editingList.value.status = ListStatus.ACTIVE
            await store.dispatch("changePurchasingList", { list: editingList.value, listRows: savingRows })
            await store.dispatch("saveReceiptsAndPictures", { savingReceipts, listId: editingList.value.id })
            backToListTable()
        }

        const completeList = () => {
            editingList.value.status = ListStatus.DONE
            editingList.value.completedAt = moment().toISOString()
            editingList.value.completedAtForDisplaying = moment().format(FULLDATE_TIME_FORMAT)
            saveList()
        }
        const resetList = () => {
            store.commit("setInitialRows")
        }

        const uploadList = () => uploadListToXLS(editingList.value, listRows.value, UploadListType.BUYER)

        //Return button
        const renderKey = ref(0)
        const returnButtonVisible = computed(() => editingList.value.status === ListStatus.DONE)
        const returnToWork = () => {
            editingList.value.status = ListStatus.ACTIVE
            editingList.value.translatedStatus = TranslationListStatus[editingList.value.status as ListStatus]
            editingList.value.completedAt = undefined
            editingList.value.completedAtForDisplaying = ""
            store.dispatch("returnToWork", editingList.value)
            store.dispatch("processExistingList", [editingList.value, ListActionType.EDIT])
            renderKey.value++
        }

        //Confirmation dialog
        const confirmationDialogVisible = ref(false)
        const confirmationDialogMainText = ref([""])
        const actionType = ref(ListActionType.SAVE)
        const showConfirmationDialog = (action: ListActionType, row?: PurchasingListRow) => {
            confirmationDialogVisible.value = true
            switch (action) {
                case ListActionType.SAVE:
                    actionType.value = ListActionType.SAVE
                    confirmationDialogMainText.value = [ConfirmationMessages.SAVE_LIST]
                    return

                case ListActionType.COMPLETE:
                    actionType.value = ListActionType.COMPLETE;
                    confirmationDialogMainText.value = [ConfirmationMessages.COMPLETE]
                    return

                case ListActionType.RESET:
                    actionType.value = ListActionType.RESET;
                    confirmationDialogMainText.value = [ConfirmationMessages.RESET]
                    return

                case ListActionType.DELETE:
                    actionType.value = ListActionType.DELETE;
                    confirmationDialogMainText.value = [ConfirmationMessages.DELETE_RECEIPT]
                    return

                case ListActionType.RETURN:
                    actionType.value = ListActionType.RETURN;
                    confirmationDialogMainText.value = [ConfirmationMessages.RETURN_LIST]
                    return

                case ListActionType.DELETE_ROW:
                    actionType.value = ListActionType.DELETE_ROW;
                    confirmationDialogMainText.value = [ConfirmationMessages.DELETE_ROW]
                    rowForDeleting.value = row!
                    return
            }
        }
        const hideConfirmationDialog = () => confirmationDialogVisible.value = false
        const rowForDeleting = ref<PurchasingListRow>({ isPurchased: false })
        const okClicked = () => {
            hideConfirmationDialog()
            switch (actionType.value) {
                case ListActionType.SAVE:
                    saveList()
                    return

                case ListActionType.COMPLETE:
                    completeList()
                    return

                case ListActionType.RESET:
                    resetList()
                    return

                case ListActionType.RETURN:
                    returnToWork()
                    return

                case ListActionType.DELETE_ROW:
                    deleteRow(rowForDeleting.value)
                    return
            }
        }

        return {
            isNightSelect,
            backToListTable,
            ingredientGroupSwitched,
            purchasedRowsVisibleSwitched,
            editingList,
            isEditingAllowed,
            listRows,
            processPurchasing,
            getTableData,
            editingDialogVisible,
            showEditingDialog,
            hideEditingDialog,
            editingRow,
            ingredientRowClicked,
            deleteRow,

            //list
            saveButtonDisabled,
            sendButtonDisabled,
            saveList,
            completeButtonDisabled,
            completeList,
            resetList,
            uploadList,
            returnButtonVisible,
            returnToWork,
            confirmationDialogVisible,
            confirmationDialogMainText,
            actionType,
            showConfirmationDialog,
            hideConfirmationDialog,
            okClicked,
            ListActionType
        }
    }
})
