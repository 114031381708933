import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, withCtx as _withCtx, normalizeClass as _normalizeClass, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-113cd1dc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "text-item left category" }
const _hoisted_2 = { class: "right" }
const _hoisted_3 = {
  key: 0,
  class: "center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_button_group = _resolveComponent("button-group")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_icon_button = _resolveComponent("icon-button")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_edit_receipt = _resolveComponent("edit-receipt")!
  const _component_dialog_window = _resolveComponent("dialog-window")!
  const _component_picture_viewer = _resolveComponent("picture-viewer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("details", null, [
      _createElementVNode("summary", _hoisted_1, "Стоимость закупки " + _toDisplayString(_ctx.listPrice), 1),
      _createElementVNode("div", _hoisted_2, [
        (_ctx.isEditingAllowed)
          ? (_openBlock(), _createBlock(_component_button_group, {
              key: 0,
              "view-full-size-buttons": _ctx.viewFullSizeButtons,
              "is-cancel-displayed": false,
              "is-copy-displayed": false,
              "is-join-displayed": false,
              "is-download-displayed": true,
              background: false,
              "edit-disabled": _ctx.editReceiptButtonDisabled,
              "delete-disabled": _ctx.deleteReceiptButtonDisabled,
              "download-disabled": _ctx.downloadReceiptButtonDisabled,
              onAddButtonClicked: _ctx.addReceipt,
              onEditButtonClicked: _ctx.editReceipt,
              onDeleteButtonClicked: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showConfirmationDialog(_ctx.ListActionType.DELETE))),
              onDownloadButtonClicked: _ctx.downloadReceipt
            }, null, 8, ["view-full-size-buttons", "edit-disabled", "delete-disabled", "download-disabled", "onAddButtonClicked", "onEditButtonClicked", "onDownloadButtonClicked"]))
          : _createCommentVNode("", true)
      ]),
      _createVNode(_component_el_table, {
        ref: "multipleTableRef",
        data: _ctx.receiptsData,
        "row-key": "id",
        border: true,
        onSelectionChange: _ctx.handleSelectionChange,
        "highlight-current-row": "",
        class: _normalizeClass({ night: _ctx.isNightSelect })
      }, {
        default: _withCtx(() => [
          (_ctx.isEditingAllowed)
            ? (_openBlock(), _createBlock(_component_el_table_column, {
                key: 0,
                type: "selection",
                width: "40",
                fixed: "",
                "reserve-selection": true
              }))
            : _createCommentVNode("", true),
          _createVNode(_component_el_table_column, {
            label: "Дата",
            prop: "dateForDisplaying",
            sortable: true,
            "min-width": "140"
          }),
          _createVNode(_component_el_table_column, {
            label: "Место закупки",
            prop: "purchasingPlaceName",
            sortable: true,
            "min-width": "100"
          }),
          _createVNode(_component_el_table_column, {
            label: "Сумма чека",
            prop: "price",
            sortable: true,
            width: "150"
          }),
          _createVNode(_component_el_table_column, {
            label: "Комментарий",
            prop: "note",
            sortable: true,
            "min-width": "110"
          }),
          _createVNode(_component_el_table_column, {
            label: "Чек",
            "min-width": "50"
          }, {
            default: _withCtx((scope) => [
              (scope.row.pictureIds.length)
                ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                    _createVNode(_component_icon_button, {
                      type: "receipt",
                      onClick: ($event: any) => (_ctx.viewReceiptPictures(scope.row))
                    }, null, 8, ["onClick"])
                  ]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["data", "onSelectionChange", "class"])
    ]),
    _createVNode(_component_edit_receipt, {
      modelValue: _ctx.editReceiptVisible,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.editReceiptVisible) = $event)),
      onCloseDialog: _ctx.hideReceiptEditing
    }, null, 8, ["modelValue", "onCloseDialog"]),
    _createVNode(_component_dialog_window, {
      modelValue: _ctx.confirmationDialogVisible,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.confirmationDialogVisible) = $event)),
      title: "Внимание",
      "main-text": _ctx.confirmationDialogMainText,
      onCloseDialog: _ctx.hideConfirmationDialog,
      onCancelClicked: _ctx.hideConfirmationDialog,
      onOkClicked: _ctx.okClicked
    }, null, 8, ["modelValue", "main-text", "onCloseDialog", "onCancelClicked", "onOkClicked"]),
    _createVNode(_component_picture_viewer, {
      modelValue: _ctx.viewerVisible,
      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.viewerVisible) = $event)),
      "is-deleting-allowed": _ctx.isEditingAllowed,
      onCloseViewer: _ctx.hideViewer,
      onNoPictures: _ctx.showReceiptEditing
    }, null, 8, ["modelValue", "is-deleting-allowed", "onCloseViewer", "onNoPictures"])
  ], 64))
}