
import { computed, defineComponent, ref } from "vue"
import { useStore } from "vuex"
import { SortingForTableDishes } from '@/modules/admin/daily-dish-menu/composables/useDishesSortingForTableDishes'
import { ElTable } from "element-plus"
//interfaces
import { Dish } from "@/utils/data/interfaces"
import DishCategoryName from "@/modules/admin/models/records/DishCategoryName";
import ToggleCheckbox from "@/common/components/ToggleCheckbox.vue"
import CustomCard from "@/common/components/CustomCard.vue";

export default defineComponent({
  name: "TableDishes",
  components: {
    CustomCard,
    ToggleCheckbox
  },
  setup(props, { emit }) {

    const test = ref(true)
    const store = useStore()
    const isNightSelect = computed(() => store.getters.getIsSelectedNight)
    const multipleTableRef = ref<InstanceType<typeof ElTable>>(); //ElementPlus type
    const tableLayout = ref("auto")
    const search = ref("")

    const handleSearchInput = (value: string) => {
      search.value = value
    }

    const tableData = computed(() => {
      return SortingForTableDishes(
        store.getters.getDishesForCreateMenu,
        category.value as string,
        search.value
      ) as Array<Dish>
    })

    const addRemoveDishInMenu = (dish: Dish) => {
      store.dispatch('addRemoveDishInMenu', dish)
    }
    const dishSelected = ((dish: Dish) => {
      addRemoveDishInMenu(dish)
    })

    const category = ref('')
    const categoryOptions = ref(Object.values(DishCategoryName).map(item => {
      return {
        value: item,
        label: item,
      }
    }))

    return {
      category,
      categoryOptions,
      search,
      handleSearchInput,
      dishSelected,
      tableData,
      tableLayout,
      test,
      multipleTableRef,
      isNightSelect,
    }
  },
})
