
import store from "@/store"
import { computed, defineComponent, ref } from "vue"
import { ElTable } from 'element-plus'
import { ListActionType, ConfirmationMessages } from "@/modules/admin/purchasing-products/models/enums"
import { Receipt } from "@/modules/admin/purchasing-products/models/listInterfaces"
import ButtonGroup from "@/modules/admin/purchasing-products/components/ButtonGroup.vue"
import EditReceipt from "@/modules/admin/purchasing-products/components/EditReceipt.vue"
import PictureViewer from "@/modules/admin/purchasing-products/components/PictureViewer.vue"
import moment from "moment"
import { FULLDATE_FORMAT } from "@/utils/data/moment-formats"
import { addReceiptPictures } from "@/modules/admin/purchasing-products/models/helpers"

export default defineComponent({
    name: "Receipts",
    components: {
        ButtonGroup,
        EditReceipt,
        PictureViewer,
    },
    props: {
        isEditingAllowed: {
            type: Boolean,
            default: true
        }
    },
    setup(props) {
        const isNightSelect = computed(() => store.getters.getIsSelectedNight)
        //Table checkboxes handling
        const multipleTableRef = ref<InstanceType<typeof ElTable>>()
        const selectedRows = ref<Receipt[]>([])
        const handleSelectionChange = (val: Receipt[]) => {
            selectedRows.value = val
        }

        //Data forming
        const receiptsData = computed(() =>
            (Array.from(store.getters.getReceiptsTableData.values()) as Array<Receipt>)
                .map((receipt: Receipt) => {
                    receipt.dateForDisplaying = moment(receipt.date).format(FULLDATE_FORMAT)
                    if (store.getters.getPurchasingPlaces.has(receipt.purchasingPlaceId))
                        receipt.purchasingPlaceName = store.getters.getPurchasingPlaces.get(receipt.purchasingPlaceId).name ?? ""
                    return receipt
                })
        )

        const listPrice = computed<string>(() => {
            if (store.getters.getReceiptsTableData) {
                const price: number = (Array.from(store.getters.getReceiptsTableData.values()) as Array<Receipt>)
                    .reduce((res, receipt) => res += receipt.price!, 0)
                store.commit("setListPrice", price)
                return `${price.toString()} ₽`
            } else return ""
        })

        //Editing dialog
        const editReceiptVisible = ref(false)
        const hideReceiptEditing = () => {
            editReceiptVisible.value = false
            selectedRows.value = []
            multipleTableRef.value?.clearSelection()
        }
        const showReceiptEditing = () => editReceiptVisible.value = true

        //Buttons handling
        const editReceiptButtonDisabled = computed(() => selectedRows.value.length !== 1)
        const deleteReceiptButtonDisabled = computed(() => selectedRows.value.length === 0)
        const downloadReceiptButtonDisabled = computed(() => selectedRows.value.length !== 1)
        const viewFullSizeButtons = computed(() => !store.getters.getBreakpoints.mobile)

        const setEditingReceipt = async (receipt: Receipt, action: ListActionType) => {
            store.commit("setReceiptActionType", action)
            const editingReceipt = receipt
            if (!editingReceipt.mapId) {
                store.commit("nextRowLocalId")
                editingReceipt.mapId = store.getters.getRowLocalId
            }
            store.commit("setEditingReceipt", editingReceipt)
            store.commit("setDefaultReceipt", { ...editingReceipt })
        }
        const addReceipt = () => {
            setEditingReceipt({}, ListActionType.ADD)
            showReceiptEditing()
        }
        const editReceipt = () => {
            setEditingReceipt(selectedRows.value[0], ListActionType.EDIT)
            showReceiptEditing()
        }

        const downloadReceipt = (files: File[]) => {
            setEditingReceipt(selectedRows.value[0], ListActionType.EDIT)
            addReceiptPictures(files)
            multipleTableRef.value?.clearSelection()
        }

        const deleteReceipt = () => {
            selectedRows.value.forEach((receipt) => store.commit("removeReceipt", receipt))
            selectedRows.value = []
            multipleTableRef.value?.clearSelection()
        }

        //Confirmation dialog
        const confirmationDialogVisible = ref(false)
        const confirmationDialogMainText = ref([""])
        const actionType = ref(ListActionType.SAVE)
        const showConfirmationDialog = (action: ListActionType) => {
            confirmationDialogVisible.value = true
            switch (action) {
                case ListActionType.DELETE:
                    actionType.value = ListActionType.DELETE;
                    confirmationDialogMainText.value = [ConfirmationMessages.DELETE_RECEIPT]
                    return
            }
        }

        const hideConfirmationDialog = () => confirmationDialogVisible.value = false
        const okClicked = () => {
            hideConfirmationDialog()
            switch (actionType.value) {
                case ListActionType.DELETE:
                    deleteReceipt()
                    return
            }
        }
        //Pictures viewer
        const viewerVisible = ref(false)
        const showViewer = () => viewerVisible.value = true
        const hideViewer = () => viewerVisible.value = false

        const viewReceiptPictures = async (receipt: Receipt) => {
            setEditingReceipt(receipt, ListActionType.EDIT)
            showViewer()
        }

        return {
            isNightSelect,
            multipleTableRef,
            handleSelectionChange,
            receiptsData,
            editReceiptVisible,
            hideReceiptEditing,
            showReceiptEditing,
            editReceiptButtonDisabled,
            deleteReceiptButtonDisabled,
            downloadReceiptButtonDisabled,
            viewFullSizeButtons,
            addReceipt,
            editReceipt,
            downloadReceipt,
            deleteReceipt,
            viewReceiptPictures,
            listPrice,
            confirmationDialogVisible,
            confirmationDialogMainText,
            actionType,
            showConfirmationDialog,
            hideConfirmationDialog,
            okClicked,
            ListActionType,
            viewerVisible,
            showViewer,
            hideViewer
        }
    }
})
