

import {useStore} from "vuex"
import {computed} from "@vue/runtime-core";
import {MenuLink} from "@/modules/portal/models/interfaces/menu-links-interface";
import {useRouter} from "vue-router";
import {defineComponent} from "vue";
import {LogoUrls} from "@/modules/order/models/data/enums";
import MenuItem from "@/modules/order/components/lunch-ordering/MenuItem.vue";
import DayNightToggleSwitchVue from "@/common/components/buttons/DayNightToggleSwitch.vue"
export default defineComponent({
  name: "Menu",
  components: {MenuItem, DayNightToggleSwitchVue},
  setup() {
    const store = useStore()
    const router = useRouter()
    const isNightSelect = computed(() => store.getters.getIsSelectedNight)
    const dayNightLogo = computed(() => isNightSelect.value ? LogoUrls.LOGO_NIGHT : LogoUrls.LOGO_DAY)
    const menuShown = computed(() => store.getters.isShowMenu)
    const menuIconClick = () => {
      store.dispatch("toggleMenuIcon")
    }
    // stop redirecting to parent
    const checkToChild = (toLink: string): boolean => router.currentRoute.value.path.includes(toLink)

    const selectedMenu = computed(() => {
      if (store.getters.isUserAuthorized) {
        return store.getters.getActiveMenu as Array<MenuLink>
      }
    })
    const handleLinkClick = (menuLink: MenuLink) => {
      store.dispatch("closeMenu")
      router.push(menuLink.link)
      if (!checkToChild(menuLink.link)) {
        router.push(menuLink.link)
      }
    }
    const isMobile = computed(()=>store.getters.getBreakpoints.mobile )

    return {
      menuIconClick,
      handleLinkClick,
      selectedMenu,
      menuShown,
      dayNightLogo,
      isNightSelect,
      isMobile,
    }
  }
})
