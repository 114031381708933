export enum TypeRequests {
  POST = "post",
  PUT = "put",
  GET = "get",
  DELETE = "delete",
}

export enum Roles {
  ADMIN = "admin",
  USER = "user",
}

export enum timeSettings {
  ORDER_TIME_LIMIT = "ORDERTIMELIMIT",
  AUTO_ISSUING = "AUTOISSUING",
  CANCELED_ORDER_NOTIFICATION = "CANCELEDORDERNOTIFICATION",
}

export enum SystemMessages {
  REQUEST_EXECUTED = "Запрос выполнится, когда появится сеть",
  SOMETHING_WENT_WRONG = "Что-то пошло не так",
  LIKED = "Вы поставили лайк",
  UNLIKED = "Вы убрали лайк",
  COMMENT_ADDED = "Комментарий добавлен",
  NO_ORDERS = "Текущие заказы не найдены",
  NO_ORDER_HISTORY = "Заказы на указанный период не найдены",
  DISH_MENU_FAIL = "Ошибка при загрузке меню",
  ORDER_FAIL = "Ошибка при загрузке заказов",
  LIKE_FAIL = "Ошибка при загрузке лайков",
  COMMENT_FAIL = "Ошибка при загрузке комментов",
  ORDER_DELETED = "Заказ удален",
  ORDER_SAVED = "Заказ сохранен",
  ORDER_CHANGED = "Заказ изменен",
  ORDER_UNFORMED = "Заказ расформирован",
  ORDER_WILL_BE_DELETED = "Ваш заказ на этот день будет удален",
  ORDER_IS_ISSUED = "Заказ выдан",
  ORDER_IS_NOT_ALLOWED = "Прием заказов на эту дату закрыт",
  SPARE_ORDER = "Заказ из свободных блюд",
  UNFORMED_ORDER = "Заказ был расформирован",
  CHOOSE_DISH = "Для сохранения заказа выберите блюдо",
  DISH_MENU_IS_ABSENT_TODAY = "Нет меню на сегодня",
  SETTINGS_SENT = "Настройки сохранены",
  SETTINGS_FAIL = "Уведомления не загружены",
  MAXIMUM_DISH_AMOUNT = "Максимальное количество блюд",
  FIELDS_CLEARED = "Поля заказа очищены",
  //Admin's messages
  DISH_ADDED = "Блюдо добавлено",
  DISH_UPDATED = "Блюдо обновлено",
  DISH_DELETED = "Блюдо удалено",
  DISH_RESTORED = "Блюдо восстановлено",
  INGREDIENT_ADDED = "Ингредиент добавлен",
  INGREDIENT_UPDATED = "Ингредиент обновлен",
  INGREDIENT_DELETED = "Ингредиент удален",
  INGREDIENT_RESTORED = "Ингредиент восстановлен",
  INGREDIENT_ALREADY_EXISTS = "Ингредиент с таким названием уже существует",
  MENU_SAVED = "Меню сохранено",
  MENU_UPDATED = "Меню отправлено",
  MENU_DELETED = "Меню очищено",
  MENU_EMPTY = "Меню пустое",
  MENU_IN_USE = "На этот день есть заказы",
  SPERE_DISHES_ADDED = "Блюда добавлены",
  PERMISSIONS_SAVED = "Права пользователя сохранены",
  PERMISSIONS_NOT_SAVED = "Права пользователя не были сохранены",
  ROLES_SAVED = "Роли пользователя сохранены",
  PROLES_NOT_SAVED = "Роли пользователя не были сохранены",
  TIMESETTINGS_SAVE = "Настройки времени сохранены",
  SPARE_DISH_ADDED = "Блюдо добавлено",
  ALREADY_EXIST = "Такая позиция уже существует",
  //common messages
  AUTH_NOT_OK = "Неверный логин или пароль",
  INGREDIENT_AMOUNT_REQUIRED = "Введите вес ингредиента",
  // temporary for 405 code
  DISH_IN_USE = "Данное блюдо используется в системе",
  DISH_ALREADY_EXISTS = "Блюдо с таким названием уже существует",
  //Emoji messages
  EMOJI_SAVED = "Эмодзи сохранено",
  EMOJI_CHANGED = "Эмодзи изменено",
  EMOJI_DELETED = "Эмодзи удалено",
  //Purchasing products messages
  SAVED = "Сохранено",
  CHANGED = "Изменено",
  DELETED = "Удалено",
  CANCELED = "Отменено",
  CHOOSE_LIST = "Для совершения действия выберите список",
  CHOOSE_ONLY_ONE_LIST = "Данное действие применимо только к одному списку",
  SENT = "Отправлено",
  RETURNED = "Возвращено в работу",
  NO_RECEIPTS = "Чеки отсутствуют",
  ADDED = "Добавлено",
  RECEIPT_ERROR = "Ошибка при сохранении чеков",
  //
  USERS_EMPTY = "Пользователи не выбраны",
  USERS_ADDED = "Пользователи выбраны",
  USERS_UPDATED = "Пользователи обновлены",
  USER_SAVED = "Пользователи сохранены",
  REMINDER_SENT = "Напоминание отправлено",
  USERS_HAS_ZERO_AMOUNT = "Удалите пользователей с суммой 0",
  SAVE_USER = "Сохраните изменения"
}
export enum LogoUrls {
  LOGO_DAY = "/images/logo/logoRelexDay.svg",
  LOGO_NIGHT = "/images/logo/logoRelexNight.svg",
}

export enum DayNightStatuses {
  DAY = "day",
  NIGHT = "night",
}
export enum TypeMessages {
  SUCCESS = "success",
  WARNING = "warning",
  INFO = "info",
  ERROR = "error",
}

export enum MenuTypeImages {
  COMMON = "../images/menu-icons/common-menu-icon.svg",
  DIET = "../images/menu-icons/diet-menu-icon.svg",
  PREMIUM = "../images/menu-icons/premium-menu-icon.svg",
}

export enum LikeIcons {
  LIKED = "../images/common-icons/dishLiked.svg",
  UNLIKED = "../images/common-icons/dishUnliked.svg",
}

export enum SideButtons {
  PLUS = "../images/common-icons/plus.svg",
  MINUS = "../images/common-icons/minus.svg",
}

export enum QuestionMark {
  DEACTIVATED_NIGHT = "/images/common-icons/questionMarkNight.svg",
  ACTIVATED_NIGHT = "/images/common-icons/questionMarkNightActive.svg",
  DEACTIVATED = "/images/common-icons/questionMark.svg",
  ACTIVATED = "/images/common-icons/questionMarkActive.svg",
}

export enum ArrowImages {
  RIGHT = "../images/common-icons/arrow-right.svg",
  LEFT = "../images/common-icons/arrow-left.svg",
  RIGHT_DISABLED = "../images/common-icons/arrow-right-disabled.svg",
  LEFT_DISABLED = "../images/common-icons/arrow-left-disabled.svg",
  RIGHT_NIGHT = "../images/common-icons/arrow-right-night.svg",
  LEFT_NIGHT = "../images/common-icons/arrow-left-night.svg",
  RIGHT_DISABLED_NIGHT = "../images/common-icons/arrow-right-disabled-night.svg",
  LEFT_DISABLED_NIGHT = "../images/common-icons/arrow-left-disabled-night.svg",
}

export enum RoundCheckbox {
  CHECK = "../images/common-icons/check.svg",
  UNCHECK = "../images/common-icons/uncheck.svg",
}

export enum IconButtons {
  //For dish guidebooks
  DELETE = "../images/issue-buttons/delete.svg",
  EDIT = "../images/issue-buttons/edit.svg",
  REDO = "../images/issue-buttons/redo.svg",
  DELETE_HOVERED = "../images/issue-buttons/delete-hovered.svg",
  EDIT_HOVERED = "../images/issue-buttons/edit-hovered.svg",
  REDO_HOVERED = "../images/issue-buttons/redo-hovered.svg",
  //For purchasing products
  RESET_FILTERS = "../images/purchasing-products-images/reset-filters-button.svg",
  RESET_FILTERS_HOVER = "../images/purchasing-products-images/reset-filters-hover.svg",
  RESET_DISABLED = "../images/purchasing-products-images/reset-disabled.svg",
  ADD = "../images/purchasing-products-images/add-button.svg",
  ADD_HOVER = "../images/purchasing-products-images/add-hover.svg",
  EDIT_LIST = "../images/purchasing-products-images/edit-button.svg",
  EDIT_HOVER = "../images/purchasing-products-images/edit-hover.svg",
  EDIT_DISABLED = "../images/purchasing-products-images/edit-disabled.svg",
  CANCEL = "../images/purchasing-products-images/cancel-button.svg",
  CANCEL_HOVER = "../images/purchasing-products-images/cancel-hover.svg",
  CANCEL_DISABLED = "../images/purchasing-products-images/cancel-disabled.svg",
  COPY = "../images/purchasing-products-images/copy-button.svg",
  COPY_HOVER = "../images/purchasing-products-images/copy-hover.svg",
  COPY_DISABLED = "../images/purchasing-products-images/copy-disabled.svg",
  DOWNLOAD = "../images/purchasing-products-images/download.svg",
  DOWNLOAD_HOVER = "../images/purchasing-products-images/download-hover.svg",
  DOWNLOAD_DISABLED = "../images/purchasing-products-images/download-disabled.svg",
  RECEIPT = "../images/purchasing-products-images/receipt.svg",
  RECEIPT_HOVER = "../images/purchasing-products-images/receipt-hover.svg",
  LIST_LEFT = "../images/purchasing-products-images/view-left.svg",
  LIST_LEFT_HOVER = "../images/purchasing-products-images/left-hover.svg",
  LIST_LEFT_DISABLED = "../images/purchasing-products-images/left-disabled.svg",
  LIST_RIGHT = "../images/purchasing-products-images/view-right.svg",
  LIST_RIGHT_HOVER = "../images/purchasing-products-images/right-hover.svg",
  LIST_RIGHT_DISABLED = "../images/purchasing-products-images/right-disabled.svg",
  ROTATE = "../images/purchasing-products-images/rotate.svg",
  ROTATE_HOVER = "../images/purchasing-products-images/rotate-hover.svg",
  DELETE_BLACK = "../images/purchasing-products-images/delete-black.svg",
  DELETE_BLACK_HOVER = "../images/purchasing-products-images/delete-black-hover.svg",
  DELETE_BLACK_DISABLED = "../images/purchasing-products-images/delete-black-disabled.svg",
  CLOSE = "../images/purchasing-products-images/close.svg",
  JOIN = "../images/purchasing-products-images/join.svg",
  JOIN_HOVER = "../images/purchasing-products-images/join-hover.svg",
  JOIN_DISABLED = "../images/purchasing-products-images/join-disabled.svg",

  //Icon switchers
  SHOW_FILTERS = "../images/purchasing-products-images/show-filters-button.svg",
  HIDE_FILTERS = "../images/purchasing-products-images/hide-filters-button.svg",
  LEFT = "../images/purchasing-products-images/round-button-left.svg",
  RIGHT = "../images/purchasing-products-images/round-button-right.svg",
  SHOW_PURCHASED = "../images/purchasing-products-images/show-purchased.svg",
  HIDE_PURCHASED = "../images/purchasing-products-images/hide-purchased.svg",
  PURCHASING_PLACES_GROUP = "../images/purchasing-products-images/purchasing-places-group.svg",
  INGREDIENTS_GROUP = "../images/purchasing-products-images/ingredients-group.svg",
  PURCHASING_PLACES_GROUP_NIGHT = "../images/purchasing-products-images/purchasing-places-group-night.svg",
  INGREDIENTS_GROUP_NIGHT = "../images/purchasing-products-images/ingredients-group-night.svg",
}

export enum OrderStatusImages {
  ISSUED = "../images/common-icons/issued-order.svg",
}

export enum UtilitPictures {
  INPUT_PICTURE = "../images/common-icons/pictureInput.svg",
  INPUT_PICTURE_NIGHT = "../images/common-icons/pictureInputNight.svg",
}
