import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-61e6c7e2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "dialog-header" }
const _hoisted_2 = { class: "title" }
const _hoisted_3 = { class: "body" }
const _hoisted_4 = { class: "receipt-picture-info" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { key: 1 }
const _hoisted_7 = { class: "buttons-horizontal-panel" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_close_button = _resolveComponent("close-button")!
  const _component_el_date_picker = _resolveComponent("el-date-picker")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_download_button = _resolveComponent("download-button")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_custom_button = _resolveComponent("custom-button")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createBlock(_component_el_dialog, {
    width: _ctx.editDialogWidth,
    "show-close": false,
    "append-to-body": true,
    top: "1vh",
    onOpen: _ctx.dialogOpening,
    class: _normalizeClass({ night: _ctx.isNightSelect })
  }, {
    header: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_close_button, { onClick: _ctx.closeDialog }, null, 8, ["onClick"])
      ]),
      _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.title), 1)
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_el_form, {
          ref: "ruleFormRef",
          class: "purchasing-guide-books-form",
          "label-position": "top",
          model: _ctx.editingReceipt,
          rules: _ctx.receiptFormRules,
          size: _ctx.receiptformSize,
          onSubmit: _cache[4] || (_cache[4] = _withModifiers(() => {}, ["prevent"]))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_form_item, {
              label: "Дата закупки",
              prop: "date"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_date_picker, {
                  class: _normalizeClass({ night: _ctx.isNightSelect }),
                  type: "date",
                  name: "purchasing-date",
                  modelValue: _ctx.editingReceipt.date,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.editingReceipt.date) = $event)),
                  clearable: false,
                  editable: false,
                  format: _ctx.FULLDATE_FORMAT,
                  "value-format": "YYYY-MM-DDTHH:mm:ss"
                }, null, 8, ["class", "modelValue", "format"])
              ]),
              _: 1
            }),
            _createVNode(_component_el_form_item, {
              label: "Место закупки",
              prop: "purchasingPlaceName"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_select, {
                  modelValue: _ctx.editingReceipt.purchasingPlaceId,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.editingReceipt.purchasingPlaceId) = $event)),
                  class: _normalizeClass(["m-2", { night: _ctx.isNightSelect }]),
                  filterable: "",
                  onChange: _ctx.purchasingPlaceChanged
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.purchasingPlaceSelectOptions, (option) => {
                      return (_openBlock(), _createBlock(_component_el_option, {
                        key: option.value,
                        label: option.label,
                        value: option.value
                      }, null, 8, ["label", "value"]))
                    }), 128))
                  ]),
                  _: 1
                }, 8, ["modelValue", "class", "onChange"])
              ]),
              _: 1
            }),
            _createVNode(_component_el_form_item, {
              label: "Сумма чека",
              prop: "price"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  modelValue: _ctx.receiptPrice,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.receiptPrice) = $event)),
                  class: _normalizeClass({ night: _ctx.isNightSelect }),
                  maxlength: 6,
                  onInput: _ctx.receiptPriceChanged
                }, null, 8, ["modelValue", "class", "onInput"])
              ]),
              _: 1
            }),
            _createVNode(_component_el_form_item, {
              label: "Комментарий",
              prop: "note"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  modelValue: _ctx.editingReceipt.note,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.editingReceipt.note) = $event)),
                  maxlength: 50,
                  class: _normalizeClass({ night: _ctx.isNightSelect })
                }, null, 8, ["modelValue", "class"])
              ]),
              _: 1
            }),
            _createVNode(_component_el_form_item, null, {
              default: _withCtx(() => [
                _createVNode(_component_download_button, { onDownloadClicked: _ctx.downloadClicked }, null, 8, ["onDownloadClicked"]),
                _createElementVNode("div", _hoisted_4, [
                  (!!_ctx.receiptPicturesAmount)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_5, "Загружено чеков - " + _toDisplayString(_ctx.receiptPicturesAmount), 1))
                    : (_openBlock(), _createElementBlock("span", _hoisted_6, "Нет загруженных чеков, добавьте комментарий"))
                ])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["model", "rules", "size"]),
        _createElementVNode("div", _hoisted_7, [
          _createVNode(_component_custom_button, {
            "type-button": "success",
            disabled: _ctx.isSaveButtonDisabled,
            onClick: _ctx.saveClicked
          }, {
            default: _withCtx(() => [
              _createTextVNode("СОХРАНИТЬ")
            ]),
            _: 1
          }, 8, ["disabled", "onClick"]),
          _createVNode(_component_custom_button, {
            "type-button": "reset",
            disabled: _ctx.isResetButtonDisabled,
            onClick: _ctx.dialogOpening
          }, {
            default: _withCtx(() => [
              _createTextVNode("СБРОСИТЬ")
            ]),
            _: 1
          }, 8, ["disabled", "onClick"])
        ])
      ])
    ]),
    _: 1
  }, 8, ["width", "onOpen", "class"]))
}