import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b4be6dba"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "wrapper" }
const _hoisted_2 = { class: "top" }
const _hoisted_3 = { class: "cards" }
const _hoisted_4 = { class: "left" }
const _hoisted_5 = { class: "permission-name" }
const _hoisted_6 = { class: "right" }
const _hoisted_7 = { class: "description" }
const _hoisted_8 = { class: "permission-name" }
const _hoisted_9 = { class: "buttons-horizontal-panel" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SearchByUsers = _resolveComponent("SearchByUsers")!
  const _component_toggle_checkbox = _resolveComponent("toggle-checkbox")!
  const _component_custom_card = _resolveComponent("custom-card")!
  const _component_QuestionMarkPopover = _resolveComponent("QuestionMarkPopover")!
  const _component_custom_button = _resolveComponent("custom-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_SearchByUsers, {
        "array-users": _ctx.usersForSelect,
        onHandleUserId: _ctx.selectUserId,
        onHandleFilterValue: _ctx.catcherFilterValue
      }, null, 8, ["array-users", "onHandleUserId", "onHandleFilterValue"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_custom_card, { class: "custom-card" }, {
          header: _withCtx(() => [
            _createTextVNode("Настройки прав")
          ]),
          default: _withCtx(() => [
            _createElementVNode("div", {
              class: _normalizeClass(["card-body", { 'is-mobile': _ctx.isContentHeightMobile }])
            }, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.allPermissions, (permission) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: _normalizeClass(["card-content", { 'is-mobile': _ctx.isContentHeightMobile }]),
                  key: permission.id
                }, [
                  _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.PermissionsName[permission.name]), 1),
                  _createVNode(_component_toggle_checkbox, {
                    checked: permission.selected,
                    "onUpdate:checked": ($event: any) => ((permission.selected) = $event),
                    disabled: !_ctx.idSelectedUser,
                    onChange: ($event: any) => (_ctx.addRemovePermission(permission.id))
                  }, null, 8, ["checked", "onUpdate:checked", "disabled", "onChange"])
                ], 2))
              }), 128))
            ], 2)
          ]),
          _: 1
        })
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_custom_card, { class: "custom-card" }, {
          header: _withCtx(() => [
            _createTextVNode("Настройки ролей")
          ]),
          "header-icon": _withCtx(() => [
            _createVNode(_component_QuestionMarkPopover, null, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.AdditionalRoles, (role) => {
                  return (_openBlock(), _createElementBlock("span", _hoisted_7, [
                    _createElementVNode("strong", null, _toDisplayString(_ctx.RoleNames[role]), 1),
                    _createTextVNode(_toDisplayString(_ctx.MeaningRoles[role]), 1)
                  ]))
                }), 256))
              ]),
              _: 1
            })
          ]),
          default: _withCtx(() => [
            _createElementVNode("div", {
              class: _normalizeClass(["card-body", { 'is-mobile': _ctx.isContentHeightMobile }])
            }, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.allRoles, (role) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: "card-content",
                  key: role.id
                }, [
                  _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.RoleNames[role.name]), 1),
                  _createVNode(_component_toggle_checkbox, {
                    checked: role.selected,
                    "onUpdate:checked": ($event: any) => ((role.selected) = $event),
                    disabled: !_ctx.idSelectedUser,
                    onChange: ($event: any) => (_ctx.handleChangeRole(role))
                  }, null, 8, ["checked", "onUpdate:checked", "disabled", "onChange"])
                ]))
              }), 128))
            ], 2)
          ]),
          _: 1
        })
      ])
    ]),
    _createElementVNode("div", _hoisted_9, [
      _createVNode(_component_custom_button, {
        "type-button": "success",
        disabled: !_ctx.idSelectedUser || _ctx.disableButtons,
        onClick: _ctx.saveUserSettings
      }, {
        default: _withCtx(() => [
          _createTextVNode("СОХРАНИТЬ ")
        ]),
        _: 1
      }, 8, ["disabled", "onClick"]),
      _createVNode(_component_custom_button, {
        disabled: !_ctx.idSelectedUser || _ctx.disableButtons,
        onClick: _ctx.clearUserSettings
      }, {
        default: _withCtx(() => [
          _createTextVNode("ОЧИСТИТЬ ")
        ]),
        _: 1
      }, 8, ["disabled", "onClick"])
    ])
  ]))
}