
import {computed, defineComponent, onMounted, reactive, ref} from "vue"

import { FormInstance } from "element-plus"
import { RuleForm } from "@/modules/login/models/login-model"

import { useStore } from "vuex"

import Header from "@/modules/portal/components/header/views/Header.vue"
import Footer from "@/modules/portal/components/footer/Footer.vue"

import LogoRelex from "@/modules/portal/components/header/components/LogoRelex.vue"
import SpareDishes from "@/modules/order/components/spare-dishes/SpareDishes.vue";
import { focusElement } from "@/utils/helpers/focusElement"
import {notAllowedSymbolsForLogin} from "@/utils/data/constants";


export default defineComponent({
  name: "LoginForm",
  components: {
    SpareDishes,
    Header,
    Footer,
    LogoRelex,
  },
  setup() {
    const store = useStore()
    const isNightSelect = computed(() => store.getters.getIsSelectedNight)

    const ruleFormRef = ref<FormInstance>()
    const ruleForm = reactive<RuleForm>({
      login: "",
      password: "",
    })

    const validateLogin = (rule: any, value: string, callback: any) => {
      store.commit("clearLoginErrorInfo")


      if (ruleForm.login === "") {
        callback(new Error("Введите логин"))
        return
      } else if (ruleForm.login.match(notAllowedSymbolsForLogin)) {
        callback(new Error("Введен некорректный символ"))
        return
      } else {
        if (!ruleFormRef.value) return
        ruleFormRef.value.validateField("pass", () => null)
      }
      callback()
    }
    const validatePassword = (rule: any, value: string, callback: any) => {
      store.commit("clearLoginErrorInfo")
      if (ruleForm.password === "") {
        callback(new Error("Введите пароль"))
        return
      }
      callback()
    }

    const rules = reactive({
      login: [
        {
          validator: validateLogin,
          trigger: "blur",
        },
        {
          validator: validateLogin,
          trigger: "change",
        },
      ],
      pass: [
        {
          validator: validatePassword,
          trigger: "blur",
        },
        {
          validator: validatePassword,
          trigger: "change",
        },
      ],
    })

    const submitForm = async () => {
      if (!ruleFormRef.value) return
      await ruleFormRef.value.validate(async (isValid: any) => {
        if (isValid) {
          await store.dispatch("loginUser", ruleForm)
          localStorage.removeItem("lastClickedDate")
          await store.dispatch("initCurrentDate")
        }
      })
    }
    const resetForm = () => {
      store.commit("clearLoginErrorInfo")
      if (!ruleFormRef.value) return
      ruleForm.login = ""
      ruleForm.password = ""
    }

    onMounted(() => focusElement("login"))
    return {
      ruleForm,
      rules,
      submitForm,
      ruleFormRef,
      resetForm,
      store,
      isNightSelect
    }
  },
})
