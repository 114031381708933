<template>
  <div class="logo-picture"
       :class="{'hide': menuShown}">
    <div class="link">
      <div class="content">
          <span class="logo-relex">
            <img
              alt="Логотип компании"
              :src="dayNightLogo"
            /></span>
      </div>
      <span class="dot"/>
      <div class="content">
        <span class="logo-kitchen"
              :class="{ night: isNightSelect }" > Кухня </span>
      </div>
    </div>
  </div>
</template>

<script>
import {computed, defineComponent} from "vue";
import {useStore} from "vuex";
import {LogoUrls} from "@/modules/order/models/data/enums";

export default defineComponent({
    name: "LogoRelex",
    setup() {
      const store = useStore()
      const isNightSelect = computed(() => store.getters.getIsSelectedNight)
      const dayNightLogo = computed(()=> isNightSelect.value ? LogoUrls.LOGO_NIGHT : LogoUrls.LOGO_DAY)
      const menuShown = computed(() => store.getters.isShowMenu)
      return {
        dayNightLogo,
        isNightSelect,
        menuShown
      }
    }
  }
)
</script>

<style lang="scss" scoped>
.logo-picture {
  font-size: 38px;
  font-family: $logo-app;
  display: flex;
  align-items: center;
  z-index: 6;
  transition: $default-trasition;
  @include breakpoint-tablet() {
    scale: 0.85;
  }
}

.logo-picture.hide {
  opacity: 0;
}

.link {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 45px;
  @include breakpoint-tablet-and-down() {
    justify-content: space-evenly;
  }
}



.logo-kitchen {
  color: $day-color-text;
  transition: color 0.9s ease 0s;
  font-weight: 600;
}
.logo-kitchen.night {
  color: $night-color-text;
}
.content {
  align-items: center;
}

.dot {
  margin: auto 5px 10px 5px;
  background-color: #F1B301;
  height: 12px;
  width: 12px;
  border-radius: 100%;
}

</style>