
import {computed, defineComponent, ref} from "vue"
import {useStore} from "vuex"
import {elMessage} from "@/modules/order/models/elMessage"
import {SystemMessages, TypeMessages} from "@/modules/order/models/data/enums"

//interfaces
import {Ingredient} from "@/utils/data/interfaces"

//composables
import useIngredientsTableFilters from "@/modules/admin/ingredients-guidebook/composables/useIngredientsTableFilters"
import ToggleCheckbox from "@/common/components/ToggleCheckbox.vue";
import {notAllowedSymbolsForNumberInputs} from "@/utils/data/constants";

export default defineComponent({
  name: "ingredientsDish",
  components: {ToggleCheckbox},
  emits: ["closeDialog"],
  setup(props, {emit}) {
    const {categoryFilters} = useIngredientsTableFilters()
    const store = useStore()

    //Для автовыравнивания таблицы
    const tableLayout = ref("auto")
    //Функция поиска по имени
    const search = ref("")

    const disableResetButton = computed(() => !store.getters.getHasAnyIngredientsInDish
      || (JSON.stringify(store.getters.getBeforeEditingIngredientsForCompare) === JSON.stringify(store.getters.getIngredientsFromEditingDishForCompare))
    )

    const ingredientsTable = computed<Array<Ingredient>>(() => store.getters.getIngredientsForDishEditing(search.value))


    const filterHandlerCategory = (value: string, row: Ingredient): boolean => row.category === value

    const closeDialog = () => emit("closeDialog")

    const handleIngredient = (ingredient: Ingredient) => {
      if (ingredient.isIngredientInDish) store.dispatch("addRemoveIngredients", {...ingredient, amount: ''})
    }
    const addRemoveIngredient = (ingredient: Ingredient) => {
      store.dispatch("addRemoveIngredients", ingredient)
    }

    const addIngredientsToDish = () => {
      if (ingredientsTable.value.find((ingredient: Ingredient) =>
        ingredient.isIngredientInDish && (ingredient.amount === undefined || !(ingredient.amount >= 0))
      )) {
        elMessage(SystemMessages.INGREDIENT_AMOUNT_REQUIRED, TypeMessages.WARNING)
        return
      }
      store.dispatch("addAllSelectIngredientsToDish")
      closeDialog()
    }
    const ingredientWeightValidation = (ingredientAdded: boolean, ingredientWeight: number | string | undefined) => ingredientAdded &&
      (ingredientWeight === '' || ingredientWeight === undefined || ingredientWeight < 0 || ingredientWeight.toString().match(notAllowedSymbolsForNumberInputs))


    const setPlaceholder = (ingredientAdded: boolean): string => {
      if (ingredientAdded) {
        return "Введите вес"
      } else return ""
    }
    const resetIngredients = async () => {
      await store.dispatch("clearSelectedIngredients")
      if (store.getters.getBeforeEditingIngredients) {
        await store.getters.getBeforeEditingIngredients.forEach((ingredient: Ingredient) => {
          store.dispatch("addRemoveIngredients", ingredient)
        })
      }
      await store.dispatch("addAllSelectIngredientsToDish")
    }
    const isNightSelect = computed(() => store.getters.getIsSelectedNight)
    return {
      isNightSelect,
      handleIngredient,
      addRemoveIngredient,
      setPlaceholder,
      ingredientWeightValidation,
      search,
      tableLayout,
      ingredientsTable,
      filterHandlerCategory,
      categoryFilters,
      addIngredientsToDish,
      resetIngredients,
      closeDialog,
      disableResetButton
    }
  },
})
