import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4480d94f"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "top" }
const _hoisted_2 = { class: "scroll-wrapper" }
const _hoisted_3 = { class: "content-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_SearchInput = _resolveComponent("SearchInput")!
  const _component_dish_view = _resolveComponent("dish-view")!
  const _component_toggle_checkbox = _resolveComponent("toggle-checkbox")!
  const _component_custom_card = _resolveComponent("custom-card")!

  return (_openBlock(), _createBlock(_component_custom_card, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_el_select, {
          class: _normalizeClass({ night: _ctx.isNightSelect }),
          modelValue: _ctx.category,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.category) = $event)),
          "clear-icon": "CloseBold",
          "suffix-icon": _ctx.category ? 'CloseBold' : 'ArrowDown',
          clearable: "",
          placeholder: "Категории"
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.categoryOptions, (item) => {
              return (_openBlock(), _createBlock(_component_el_option, {
                key: item.value,
                label: item.label,
                value: item.value
              }, null, 8, ["label", "value"]))
            }), 128))
          ]),
          _: 1
        }, 8, ["class", "modelValue", "suffix-icon"]),
        _createVNode(_component_SearchInput, {
          searchValue: _ctx.search,
          placeholderValue: "Поиск по названию",
          onHandleSearch: _ctx.handleSearchInput,
          onClearSearch: _cache[1] || (_cache[1] = ($event: any) => (_ctx.handleSearchInput('')))
        }, null, 8, ["searchValue", "onHandleSearch"])
      ]),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tableData, (dish) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "row-dish",
              key: dish.dishId
            }, [
              _createVNode(_component_dish_view, {
                dish: dish,
                "total-displaying": false
              }, null, 8, ["dish"]),
              _createVNode(_component_toggle_checkbox, {
                checked: (dish.dishInMenu as boolean),
                "onUpdate:checked": ($event: any) => (((dish.dishInMenu as boolean)) = $event),
                onChange: ($event: any) => (_ctx.dishSelected(dish))
              }, null, 8, ["checked", "onUpdate:checked", "onChange"])
            ]))
          }), 128))
        ])
      ])
    ]),
    _: 1
  }))
}