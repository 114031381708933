import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-999d72e0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "page-wrapper" }
const _hoisted_2 = { class: "buttons-horizontal-panel" }
const _hoisted_3 = { class: "title-wrapper" }
const _hoisted_4 = { class: "switcher-wrapper" }
const _hoisted_5 = { class: "table-wrapper" }
const _hoisted_6 = { class: "center" }
const _hoisted_7 = { class: "pagination-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_custom_button = _resolveComponent("custom-button")!
  const _component_icon_switcher = _resolveComponent("icon-switcher")!
  const _component_filters = _resolveComponent("filters")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_icon_button = _resolveComponent("icon-button")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_el_pagination = _resolveComponent("el-pagination")!
  const _component_button_group = _resolveComponent("button-group")!
  const _component_dialog_window = _resolveComponent("dialog-window")!
  const _component_picture_viewer = _resolveComponent("picture-viewer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_custom_button, { disabled: true }, {
        default: _withCtx(() => [
          _createTextVNode("Списки закупок")
        ]),
        _: 1
      }),
      _createVNode(_component_custom_button, { onClick: _ctx.showGuideBooks }, {
        default: _withCtx(() => [
          _createTextVNode("Справочники")
        ]),
        _: 1
      }, 8, ["onClick"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", {
        class: _normalizeClass(["title", { night: _ctx.isNightSelect }])
      }, "Списки для закупки продуктов", 2),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_icon_switcher, {
          type: "switch-filters",
          onSwitched: _ctx.filtersVisibleSwitched
        }, null, 8, ["onSwitched"])
      ])
    ]),
    _withDirectives(_createVNode(_component_filters, { onFilterChanged: _ctx.filterChanged }, null, 8, ["onFilterChanged"]), [
      [_vShow, _ctx.isFiltersVisible]
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(_component_el_table, {
        ref: "multipleTableRef",
        data: _ctx.purchasingListsTableData,
        border: true,
        onRowClick: _ctx.tableRowClicked,
        onSelectionChange: _ctx.handleSelectionChange,
        "highlight-current-row": "",
        class: _normalizeClass(["purchasing-products", { night: _ctx.isNightSelect }])
      }, {
        default: _withCtx(() => [
          (!_ctx.isBuyer)
            ? (_openBlock(), _createBlock(_component_el_table_column, {
                key: 0,
                type: "selection",
                width: "40",
                fixed: ""
              }))
            : _createCommentVNode("", true),
          _createVNode(_component_el_table_column, {
            label: "№",
            prop: "dailyNumber",
            width: "50"
          }),
          _createVNode(_component_el_table_column, {
            label: "Сформирован",
            prop: "translatedFormationMethod",
            sortable: true,
            width: "170"
          }),
          _createVNode(_component_el_table_column, {
            label: "Дата создания",
            prop: "createdAt",
            sortable: true,
            "min-width": "130"
          }, {
            default: _withCtx((scope) => [
              _createElementVNode("span", null, _toDisplayString(scope.row.createdAtForDisplaying), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_el_table_column, {
            label: "Статус",
            prop: "translatedStatus",
            sortable: true,
            "min-width": "120"
          }),
          _createVNode(_component_el_table_column, {
            label: "Автор",
            prop: "authorName",
            sortable: true,
            "min-width": "110"
          }),
          _createVNode(_component_el_table_column, {
            label: "Дата завершения",
            prop: "completedAt",
            sortable: true,
            "min-width": "130"
          }, {
            default: _withCtx((scope) => [
              _createElementVNode("span", null, _toDisplayString(scope.row.completedAtForDisplaying), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_el_table_column, {
            label: "Стоимость",
            prop: "price",
            sortable: true,
            width: "130"
          }),
          _createVNode(_component_el_table_column, {
            label: "Чеки",
            type: "receipt",
            width: "75"
          }, {
            default: _withCtx((scope) => [
              _createElementVNode("div", _hoisted_6, [
                (scope.row.receiptPictureIds.length)
                  ? (_openBlock(), _createBlock(_component_icon_button, {
                      key: 0,
                      type: "receipt",
                      onClick: ($event: any) => (_ctx.viewReceiptPictures(scope.row))
                    }, null, 8, ["onClick"]))
                  : _createCommentVNode("", true)
              ])
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["data", "onRowClick", "onSelectionChange", "class"])
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createVNode(_component_el_pagination, {
        "current-page": _ctx.currentPage,
        "onUpdate:currentPage": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.currentPage) = $event)),
        class: _normalizeClass({ night: _ctx.isNightSelect }),
        "hide-on-single-page": true,
        "page-count": _ctx.pageCount,
        layout: "prev, pager, next",
        onCurrentChange: _ctx.pageChanged,
        total: 50
      }, null, 8, ["current-page", "class", "page-count", "onCurrentChange"])
    ]),
    (!_ctx.isBuyer)
      ? (_openBlock(), _createBlock(_component_button_group, {
          key: 0,
          "edit-disabled": _ctx.editButtonDisabled,
          "copy-disabled": _ctx.copyButtonDisabled,
          "join-disabled": _ctx.joinButtonDisabled,
          "cancel-disabled": _ctx.cancelButtonDisabled,
          "delete-disabled": _ctx.deleteButtonDisabled,
          onAddButtonClicked: _ctx.addList,
          onCancelButtonClicked: _ctx.cancelClicked,
          onEditButtonClicked: _ctx.editClicked,
          onCopyButtonClicked: _ctx.copyClicked,
          onJoinButtonClicked: _ctx.joinClicked,
          onDeleteButtonClicked: _ctx.deleteClicked
        }, null, 8, ["edit-disabled", "copy-disabled", "join-disabled", "cancel-disabled", "delete-disabled", "onAddButtonClicked", "onCancelButtonClicked", "onEditButtonClicked", "onCopyButtonClicked", "onJoinButtonClicked", "onDeleteButtonClicked"]))
      : _createCommentVNode("", true),
    _createVNode(_component_dialog_window, {
      modelValue: _ctx.warningDialogVisible,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.warningDialogVisible) = $event)),
      title: "Внимание",
      "main-text": _ctx.warningDialogMainText,
      onCloseDialog: _ctx.hideWarningDialog,
      onCancelClicked: _ctx.hideWarningDialog,
      onOkClicked: _ctx.processList
    }, null, 8, ["modelValue", "main-text", "onCloseDialog", "onCancelClicked", "onOkClicked"]),
    _createVNode(_component_picture_viewer, {
      modelValue: _ctx.viewerVisible,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.viewerVisible) = $event)),
      "is-deleting-allowed": false,
      "view-type": "list",
      onCloseViewer: _ctx.hideViewer
    }, null, 8, ["modelValue", "onCloseViewer"])
  ]))
}