
import { computed, defineComponent, reactive, ref, watch } from "vue"
import { FormInstance } from "element-plus"
import { useStore } from "vuex"
//interfaces
import { Dish, Emoji, ImageInterface, Ingredient } from "@/utils/data/interfaces"
//enums and references
import DishCategory from "@/modules/admin/models/enums/DishCategory"
import DishSecondCategory from "@/modules/admin/models/enums/DishSecondCategory"
import DishMenuType from "@/modules/admin/models/enums/DishMenuType"
import { UtilitPictures } from "@/modules/order/models/data/enums"

import DishCategoryName from "@/modules/admin/models/records/DishCategoryName"
import DishSecondCategoryName from "@/modules/admin/models/records/DishSecondCategoryName"
import DishMenuTypeName from "@/modules/admin/models/records/DishMenuTypeName"


import useDishRules from "@/modules/admin/dish-guidebook/composables/useChangeDishValidation"
import ingredientsDish from "@/modules/admin/dish-guidebook/components/IngredientTable.vue"
import { useRouter } from "vue-router";
import { UploadImageSettings } from "@/utils/data/constants";

import { DishActionType } from "@/utils/data/types";
import updateCPFC from "@/modules/admin/dish-guidebook/composables/useCalculateCPFC";
import useFromGetObjectImage from "@/modules/admin/dish-guidebook/composables/useFromGetObjectImage";

import { spaceValidator } from "@/utils/helpers/validators"
import { compareDish } from "@/utils/helpers/compareDish";

export default defineComponent({
  name: "editDish",
  components: {
    ingredientsDish,
  },
  setup() {
    const router = useRouter()
    const store = useStore()
    const isNightSelect = computed(() => store.getters.getIsSelectedNight)
    //dispatches
    store.dispatch("getDish", parseInt(localStorage.getItem("dishId") ?? ""))
    store.dispatch("getAllEmojiForDishEditing")
    store.dispatch("getAllIngredientsForDishEditing")

    //refs
    const dialogIngredientsDishVisible = ref(false)
    const formSize = ref("default")
    const ruleFormRef = ref<FormInstance>()

    //reactive
    let currentImageData = reactive({}) as ImageInterface

    //computed
    const dish = computed(() => {
      let tempDish = store.getters.getDishForEditing as Dish
      if (store.getters.getIngredientsForDisplayingInForm.length) {
        tempDish.ingredients = store.getters.getIngredientsForDisplayingInForm
      } else {
        tempDish.ingredients = []
      }
      if (tempDish.image && tempDish.image !== 'loaded') {
        currentImageData.imageInBase64 = tempDish.image
        currentImageData.imageLink = tempDish.image
        tempDish.image = 'loaded'
      }
      return tempDish
    })
    //dish name validation
    const validateName = () => dish.value.name = spaceValidator(dish.value.name)
    const typeOfForm = computed<DishActionType>(() => store.getters.getTypeOfEditing)
    const emojiForDish = computed(() => store.getters.getEmojisFromEditingDish)
    const isResetDisabled = computed<boolean>(() => compareDish({
      ...store.getters.getDishForEditing,
      image: currentImageData.imageInBase64,
      emojis: [...store.getters.getMapEmojisFromEditingDish] ?? [],
      ingredients: store.getters.getIngredientsFromEditingDishForCompare ?? []

    },
      {
        ...store.getters.getDishBeforeEditing,
        emojis: [...store.getters.getDishBeforeEditing?.emojis] ?? [],
        ingredients: store.getters.getBeforeEditingIngredientsForCompare ?? [],
      },
      true
    )
    )
    //watcher for CPFC
    watch(() => store.getters.getIngredientsForDisplayingInForm, () => {
      updateCPFC(
        store.getters.getIngredientsForDishEditing().filter((ingredient: Ingredient) => ingredient.isIngredientInDish),
        dish.value,
        typeOfForm.value,
        store.getters.getDeltaDishWeight)
    }, { deep: true })

    //handlers
    const addRemoveEmoji = (emoji: Emoji) => {
      store.dispatch("addRemoveEmoji", emoji)
    }

    const uploadImage = async (event: InputEvent) => {
      useFromGetObjectImage(event).then(data => {
        changeImage(currentImageData, data)
      })
      dish.value.image = "loaded"
    }

    const submitForm = async (formEl: FormInstance | undefined, dish: Dish) => {
      if (!formEl) return
      await formEl.validate(async (valid, fields) => {
        if (valid && !currentImageData.imageHasSize) {
          const newDish = {
            ...dish,
            ingredients: store.getters.getIngredientsForBack,
            emojiIds: store.getters.getEmojisForBack,
            fullSizeImage: currentImageData.imageInBase64
          }
          if (typeOfForm.value === 'post') {
            await store.dispatch("setDish", newDish)
          } else {
            await store.dispatch("updateDish", newDish)
          }
          await router.push("/Handbooks/GuideDishes")
        }
      })
    }
    const changeImage = (currentImageData: ImageInterface, newImageData?: ImageInterface) => {
      if (newImageData) {
        currentImageData.imageInBase64 = newImageData.imageInBase64
        currentImageData.imageLink = newImageData.imageLink
        currentImageData.imageHasSize = newImageData.imageHasSize
      } else {
        currentImageData.imageInBase64 = ''
        currentImageData.imageLink = ''
        currentImageData.imageHasSize = false
      }
    }
    //Сброс введенных данных
    const resetForm = async (formEl: FormInstance | undefined) => {
      if (!formEl) return
      if (typeOfForm.value === "update")
        store.dispatch("getDish", parseInt(localStorage.getItem("dishId") as string))
      else {
        store.dispatch("getDish", NaN)
        formEl.resetFields()
        changeImage(currentImageData)
      }
    }

    const OpenIngredients = () => {
      dialogIngredientsDishVisible.value = true
    }
    const CloseDialog = () => {
      dialogIngredientsDishVisible.value = false
    }
    const closeEditing = () => router.push("/Handbooks/GuideDishes")


    const rowsIngredients = { minRows: 1, maxRows: 4 }
    return {
      isNightSelect,
      rowsIngredients,
      uploadImage,
      imageData: currentImageData,
      addRemoveEmoji,
      dish,
      validateName,
      CloseDialog,
      OpenIngredients,
      dialogIngredientsDishVisible,
      emojiForDish,
      typeOfForm,
      formSize,
      submitForm,
      resetForm,
      ruleFormRef,
      ...useDishRules(),
      DishCategory,
      DishSecondCategory,
      DishMenuType,
      DishCategoryName,
      DishSecondCategoryName,
      DishMenuTypeName,
      UtilitPictures,
      UploadImageSettings,
      closeEditing,
      isResetDisabled,
    }
  },
})
