
import { useStore } from "vuex"
import { computed, defineComponent, ref, watch } from "vue"
import moment from "moment"
import router from "@/router"
import { ElTable } from 'element-plus'
import Filters from "@/modules/admin/purchasing-products/components/Filters.vue"
import ButtonGroup from "@/modules/admin/purchasing-products/components/ButtonGroup.vue"
import PictureViewer from "@/modules/admin/purchasing-products/components/PictureViewer.vue"
import { FilterObject, PurchasingList, PurchasingListRow } from "@/modules/admin/purchasing-products/models/listInterfaces"
import { ListFormed, ListStatus, ListActionType } from "@/modules/admin/purchasing-products/models/enums"
import { TranslationListFormed, TranslationListStatus } from "@/modules/admin/purchasing-products/models/records"
import { FULLDATE_TIME_FORMAT } from "@/utils/data/moment-formats"
import { applyFilters } from "@/modules/admin/purchasing-products/models/helpers"
import { getUserSurnameAndInitials } from "@/utils/helpers/getUserSurnameAndInitials"
import { AdditionalRoles } from "@/modules/admin/AppSettings/models/rolesHelper"
import { SwitcherType } from "@/modules/admin/purchasing-products/models/enums"

export default defineComponent({
    name: "PurchasingListsView",
    components: {
        Filters,
        ButtonGroup,
        PictureViewer
    },
    setup() {
        const store = useStore()
        const isBuyer = computed(() => store.getters.getUser.additionalRole === AdditionalRoles.BUYER)
        const isNightSelect = computed(() => store.getters.getIsSelectedNight)
        const showGuideBooks = () => router.push("/PurchasingListsView/PurchasingGuideBooks")

        //Filters handling
        const isFiltersVisible = ref(!!JSON.parse(localStorage.getItem(SwitcherType.FILTER) as string))
        const filtersVisibleSwitched = (isSwitched: boolean) => isFiltersVisible.value = isSwitched
        const filterObject = ref({} as FilterObject)
        const filterChanged = (object: FilterObject) => filterObject.value = object

        //Table forming
        const setFromToDates = () => {
            localStorage.setItem("dateTo", moment().add(1, "day").toISOString())
            if (!localStorage.getItem("dateFrom"))
                localStorage.setItem("dateFrom", moment(localStorage.getItem("dateTo")).subtract(30, "day").toISOString())
        }
        const downloadData = async () => {
            setFromToDates()
            await store.dispatch("downloadPurchasingLists", {
                dateFrom: localStorage.getItem("dateFrom"),
                dateTo: localStorage.getItem("dateTo")
            })
        }
        downloadData()

        watch(() => store.getters.isListsDownloaded, () => {
            const userIds = new Set<number>()
            store.getters.getPurchasingListsTableData(isBuyer.value).forEach((list: PurchasingList) => {
                if (list.authorId) userIds.add(list.authorId)
            })
            downloadUsers(Array.from(userIds))
        })
        const downloadUsers = async (ids: number[]) => await store.dispatch("downloadUsersById", ids)

        const LISTS_PER_PAGE = 6
        const purchasingListsTableData = computed<Array<PurchasingList>>(() => {
            if (store.getters.isListsDownloaded && store.getters.isUsersDownloadedByIds) {
                const tableData = applyFilters(store.getters.getPurchasingListsTableData(isBuyer.value), filterObject.value)
                    .map((tableRow: PurchasingList) => {
                        const row = {
                            ...tableRow,
                            createdAtForDisplaying: moment(tableRow.createdAt).format(FULLDATE_TIME_FORMAT),
                            completedAtForDisplaying: tableRow.completedAt ? moment(tableRow.completedAt).format(FULLDATE_TIME_FORMAT) : "",
                            translatedFormationMethod: TranslationListFormed[tableRow.formationMethod as ListFormed],
                            translatedStatus: TranslationListStatus[tableRow.status as ListStatus]
                        }
                        if (!row.authorId) row.authorName = "Kitchen"
                        else if (store.getters.getUserById(row.authorId)) row.authorName = getUserSurnameAndInitials(store.getters.getUserById(row.authorId))

                        return row
                    })
                    .sort((item1, item2) => (item1.createdAt === item2.createdAt) ? 0 : item1.createdAt! < item2.createdAt! ? 1 : -1)
                    .filter((row, index) =>
                        index >= (currentPage.value - 1) * LISTS_PER_PAGE && index < (currentPage.value - 1) * LISTS_PER_PAGE + LISTS_PER_PAGE
                    )
                if (tableData.length < LISTS_PER_PAGE && !store.getters.isEndOfData) {
                    const dateTo = localStorage.getItem("dateFrom") as string
                    const dateFrom = moment(dateTo).subtract(30, "days").toISOString()
                    localStorage.setItem("dateFrom", dateFrom)
                    store.dispatch("downloadPurchasingLists", { dateFrom, dateTo })

                }
                return tableData
            } else return []
        })

        //Table checkboxes handling
        const multipleTableRef = ref<InstanceType<typeof ElTable>>()
        const selectedRows = ref<PurchasingList[]>([])
        const handleSelectionChange = (val: PurchasingList[]) => {
            selectedRows.value = val
        }

        //Purchasing list buttons handling
        const editButtonDisabled = computed(() =>
            selectedRows.value.length !== 1
            || selectedRows.value[0].status === ListStatus.CANCELED
            || selectedRows.value[0].status === ListStatus.DONE)

        const copyButtonDisabled = computed(() => selectedRows.value.length > 1 || selectedRows.value.length === 0)

        const joinButtonDisabled = computed(() =>
            selectedRows.value.length <= 1 || !!selectedRows.value.find(row => row.status !== ListStatus.DRAFT)
        )

        const cancelButtonDisabled = computed(() =>
            (selectedRows.value.length === 0)
            || (!!selectedRows.value.find((row) => row.status !== ListStatus.NEW)))

        const deleteButtonDisabled = computed(() =>
            (selectedRows.value.length === 0)
            || !!selectedRows.value.find((row) => row.status !== ListStatus.DRAFT))

        const addList = () => {
            store.dispatch("addPurchasingList")
            router.push("/PurchasingListsView/PurchasingList")
        }
        const editClicked = () => {
            store.dispatch("processExistingList", [selectedRows.value[0], ListActionType.EDIT])
            router.push("/PurchasingListsView/PurchasingList")
        }
        const tableRowClicked = async (row: PurchasingList, column: { type: string }) => {
            if (store.getters.getUser.additionalRole === AdditionalRoles.BUYER && column.type !== "receipt") {
                store.dispatch("processExistingList", [row, ListActionType.EDIT])
                router.push("/PurchasingListsView/ListForBuyer")
            } else if (column.type !== "selection" && column.type !== "receipt") {
                store.dispatch("processExistingList", [row, ListActionType.VIEW])
                router.push("/PurchasingListsView/PurchasingList")
            }
        }
        const copyClicked = () => {
            store.dispatch("processExistingList", [selectedRows.value[0], ListActionType.COPY])
            router.push("/PurchasingListsView/PurchasingList")
        }
        const joinClicked = () => {
            showWarningDialog([`Вы хотите объединить списки закупки?`])
            localListAction.value = "join"
        }
        const joiningConfirmed = async () => {
            const joinedList: PurchasingList = {
                formationMethod: ListFormed.MANUAL,
                authorId: store.getters.getUserId,
                status: ListStatus.DRAFT
            }
            store.commit("resetJoinedRows")
            selectedRows.value.forEach(async (list: PurchasingList) =>
                await store.dispatch("downloadPurchasingListRows", list.id))

            const joinedRows: Array<PurchasingListRow> = store.getters.getJoinedRows

            selectedRows.value.forEach((row) => store.dispatch("deletePurchasingList", row))

            await store.dispatch("savePurchasingList", { list: joinedList, listRows: joinedRows })
            downloadData()
        }

        const listOrLists = computed<string>(() => selectedRows.value.length === 1 ? "список" : "списки")
        const localListAction = ref<"cancel" | "delete" | "join">("cancel")
        const cancelClicked = () => {
            showWarningDialog([`Вы хотите отменить ${listOrLists.value} закупки?`])
            localListAction.value = "cancel"
        }
        const deleteClicked = () => {
            showWarningDialog([`Вы хотите удалить ${listOrLists.value} закупки?`])
            localListAction.value = "delete"
        }
        const processList = () => {
            hideWarningDialog()
            if (localListAction.value === "join") joiningConfirmed()
            else selectedRows.value.forEach((list) => {
                if (localListAction.value === "cancel") {
                    list.status = ListStatus.CANCELED
                    store.dispatch("changePurchasingList", { list, listRows: [] })
                } else store.dispatch("deletePurchasingList", list)
            })
        }

        //Receipt pictures viewing
        const viewerVisible = ref(false)
        const showViewer = () => viewerVisible.value = true
        const hideViewer = () => viewerVisible.value = false
        const viewReceiptPictures = async (list: PurchasingList) => {
            await store.dispatch("downloadPicturesByListId", list.id)
            showViewer()

        }

        //Warning dialog
        const warningDialogVisible = ref(false)
        const showWarningDialog = (message: string[]) => {
            warningDialogMainText.value = message
            warningDialogVisible.value = true
        }
        const hideWarningDialog = () => warningDialogVisible.value = false
        const warningDialogMainText = ref([""])

        //Pagination
        const currentPage = ref(parseInt(localStorage.getItem("currentPage") ?? "1"))
        const pageChanged = () => localStorage.setItem("currentPage", currentPage.value.toString())
        const pageCount = computed(() => {
            const tablerowsAmount: number =
                applyFilters(store.getters.getPurchasingListsTableData(isBuyer.value), filterObject.value).length
            if (tablerowsAmount % 6 === 0) return tablerowsAmount / 6
            else return Math.ceil(tablerowsAmount / 6)
        })

        return {
            isBuyer,
            isNightSelect,
            showGuideBooks,
            isFiltersVisible,
            filtersVisibleSwitched,
            filterChanged,
            multipleTableRef,
            handleSelectionChange,
            purchasingListsTableData,
            editButtonDisabled,
            copyButtonDisabled,
            joinButtonDisabled,
            cancelButtonDisabled,
            deleteButtonDisabled,
            addList,
            editClicked,
            tableRowClicked,
            copyClicked,
            joinClicked,
            cancelClicked,
            deleteClicked,
            processList,
            warningDialogVisible,
            showWarningDialog,
            hideWarningDialog,
            listOrLists,
            viewerVisible,
            hideViewer,
            viewReceiptPictures,
            warningDialogMainText,
            currentPage,
            pageChanged,
            pageCount
        }
    }
})
