import { Module } from "vuex"
import { ListActionType } from "@/modules/admin/purchasing-products/models/enums"

import { Receipt, ReceiptPicture } from "@/modules/admin/purchasing-products/models/listInterfaces"
import {
  downloadReceipts,
  downloadPicturesByReceiptId,
  downloadPicturesByListId,
  saveReceiptsAndPictures,
} from "@/modules/admin/purchasing-products/models/fetch-modules/fetchReceipts"
import store from "@/store"
import { elMessage } from "@/modules/order/models/elMessage"
import { SystemMessages, TypeMessages } from "@/modules/order/models/data/enums"

export const receiptsModule: Module<any, any> = {
  state: {
    editingReceipt: {} as Receipt,
    defaultReceipt: {} as Receipt,

    receiptActionType: ListActionType.ADD,
    receiptsTableData: new Map<number, Receipt>(),
    //Receipts from backend
    initialReceipts: [] as Array<Receipt>,
    //Pictures in Base64 added for current editing receipt
    addedReceiptPictures: [] as Array<string>,
    //Pictures for editing list downloaded from backend
    downloadedReceiptPictures: [] as Array<ReceiptPicture>,
    //Picture interfaces added and downloaded for all receipts in purchasing list
    picturesStore: new Map<number, Array<ReceiptPicture>>(),
    //Receipt pictures downloaded by listId
    listReceiptPictures: new Map<number, ReceiptPicture>(),
  },
  getters: {
    getEditingReceipt(state) {
      return state.editingReceipt
    },
    getDefaultReceipt(state) {
      return state.defaultReceipt
    },
    getReceiptActionType(state) {
      return state.receiptActionType
    },
    getInitialReceipts(state) {
      return state.initialReceipts
    },
    getReceiptsTableData(state) {
      return state.receiptsTableData
    },
    getAddedReceiptPictures(state) {
      return state.addedReceiptPictures
    },
    getDownloadedReceiptPictures(state) {
      return state.downloadedReceiptPictures
    },
    getPicturesFromStore: (state) => (mapId: number) => {
      return state.picturesStore.get(mapId) as Array<ReceiptPicture>
    },
    getListReceiptPictures(state) {
      return state.listReceiptPictures
    },
  },
  mutations: {
    //Edit receipt
    setEditingReceipt(state, receipt) {
      state.editingReceipt = receipt
    },
    setDefaultReceipt(state, receipt) {
      state.defaultReceipt = receipt
    },
    resetEditingReceipt(state) {
      state.editingReceipt = { ...state.defaultReceipt }
    },
    setReceiptActionType(state, receiptActionType) {
      state.receiptActionType = receiptActionType
    },

    //Recepts handling
    fetchReceipts(state, data) {
      state.initialReceipts = data
    },
    makeReceiptsTable(state) {
      state.initialReceipts.forEach((receipt: Receipt) => {
        store.commit("nextRowLocalId")
        receipt.mapId = store.getters.getRowLocalId
        state.receiptsTableData.set(receipt.mapId, receipt)
      })
    },
    addReceipt(state, receipt: Receipt) {
      state.receiptsTableData.set(receipt.mapId, receipt)
    },
    removeReceipt(state, receipt: Receipt) {
      state.receiptsTableData.delete(receipt.mapId)
    },
    clearReceiptsTable(state) {
      state.initialReceipts = []
      state.receiptsTableData = new Map<number, Receipt>()
    },
    //Receipt pictures handling
    addReceiptPicture(state, picture: string) {
      state.addedReceiptPictures.push(picture)
    },
    resetAddedReceiptPictures(state) {
      state.addedReceiptPictures = []
    },
    makePicturesStore(state) {
      (Array.from(state.receiptsTableData.values()) as Array<Receipt>)
        .forEach((receipt) => {
          const receiptPictures: Array<ReceiptPicture> = []
          receipt.pictureIds?.forEach((id) => receiptPictures
            .push({ ...state.listReceiptPictures.get(id), receiptId: receipt.mapId }))
          state.picturesStore.set(receipt.mapId, receiptPictures)
        })
    },
    addSinglePictureToStore(state, pictureInBase64: string) {
      store.commit("nextRowLocalId")
      const picture: ReceiptPicture = {
        id: store.getters.getRowLocalId,
        picture: pictureInBase64,
        receiptId: state.editingReceipt.mapId,
        purchasingListId: store.getters.getEditingList.id
      }
      state.editingReceipt.pictureIds.push(picture.id)
      if (state.picturesStore.has(picture.receiptId))
        state.picturesStore.get(picture.receiptId).push(picture)
      else state.picturesStore.set(picture.receiptId, [picture])
    },

    addPicturesToStore(state) {
      const pictureArray: Array<ReceiptPicture> = []
      const mapId = state.editingReceipt.mapId
      state.addedReceiptPictures.forEach((pictureInBase64: string) => {
        store.commit("nextRowLocalId")
        const picture: ReceiptPicture = {
          id: store.getters.getRowLocalId,
          picture: pictureInBase64,
          receiptId: mapId,
          purchasingListId: store.getters.getEditingList.id
        }
        pictureArray.push(picture)
      })
      state.picturesStore.set(mapId, [...pictureArray, ...state.downloadedReceiptPictures])
      state.editingReceipt.pictureIds = state.picturesStore
        .get(mapId)
        .map((picture: ReceiptPicture) => picture.id)
      state.addedReceiptPictures = []
    },
    fetchDownloadedReceiptPictures(state, data: Array<ReceiptPicture>) {
      state.downloadedReceiptPictures = data
    },
    resetDownloadedReceiptPictures(state) {
      state.downloadedReceiptPictures = []
    },

    clearPicturesStore(state) {
      state.picturesStore = new Map<number, ReceiptPicture>()
    },
    fetchListReceiptPictures(state, data) {
      state.listReceiptPictures.clear()
      data.forEach((picture: ReceiptPicture) =>
        state.listReceiptPictures.set(picture.id, picture))

    },
  },
  actions: {
    downloadReceipts: async ({ commit }, listId) => {
      const res = await downloadReceipts(listId)
      if (res.ok) {
        const data = await res.json()
        commit("clearReceiptsTable")
        commit("fetchReceipts", data.data)
        commit("makeReceiptsTable")
        commit("clearPicturesStore")
        commit("makePicturesStore")
      } else elMessage(SystemMessages.SOMETHING_WENT_WRONG, TypeMessages.ERROR)
    },
    downloadPicturesByReceiptId: async ({ commit }, receiptId) => {
      const res = await downloadPicturesByReceiptId(receiptId)
      if (res.ok) {
        const data = await res.json()
        commit("fetchDownloadedReceiptPictures", data.data)
      }
    },
    downloadPicturesByListId: async ({ commit }, listId) => {
      const res = await downloadPicturesByListId(listId)
      if (res.ok) {
        const data = await res.json()
        commit("fetchListReceiptPictures", data.data)
      }
    },
    saveReceiptsAndPictures: async ({ commit }, { savingReceipts, listId }) => {
      const res = await saveReceiptsAndPictures(savingReceipts, listId)
      if (!res.ok) elMessage(SystemMessages.RECEIPT_ERROR, TypeMessages.ERROR)
    }
  },
}

export default receiptsModule
